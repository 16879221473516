<template>
    <div class="body">
        <!-- banner区域 Start -->
        <div class="banner">
            <m-top-bar></m-top-bar>
        </div>
        <!-- banner区域 End -->

        <!-- switchBar区域 Start -->
        <div class="switchBar">
            <div class="switchBox">杭州BRT快速公交无线视频传输项目</div>
        </div>
        <div class="line"></div>
        <!-- switchBar区域 End -->

        <!-- container区域 Start -->
        <div class="container">
            <div class="containerBox">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BRT(Bus Rapid Transit)快速公交目前杭州已经开通3 条快速线路，做为全国快速公交数量及运行领先的杭州，
                杭州公交总公司为了更好地便于每个站台的安全管理、实时的了解每个站点运行状况及乘客的流量，
                在每路快速公交停靠站点的相对应的两个站台分别安装了四路摄像机，用无线桥接方式将相对的两个站台进行相连，
                再通过光纤的方式传输到公交总公司监控中心，
                从而实现中心控制室能实时的了解观看每个站台实际运作状况及相关乘客的流量。<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BRT3号线已于2009 年年底已经成功开通一期(丁桥到城站河坊街，共计13 个停靠站点，
                通过新诺网络科技工程师配合当地SI商与杭州公交公司沟通测试最后确认，
                采用了新诺室外网桥系列做为每个站点相对应的两个站台视频传输设备，合计使用了26 套，
                结合室外网桥的双频内置外置天线可选择的特点，实现了13对站台之间通过内置天线和外接天线两种模式无线视频数据的实时传输，
                项目设备使用至今，运行状况非常良好，得到相关交 通行业同行人士的认可，
                又为新诺产品在杭州公共项目继每年一度的烟花大会数据控制信号传输后新增交通行业应用。
            </div>
        </div>
        <!-- container区域 End -->

        <!-- baseBar区域 Start -->
        <m-base-bar></m-base-bar>
        <!-- baseBar区域 End -->
    </div>
</template>

<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
    name: 'Support',
    components: {
        mTopBar,
        mBaseBar,
    }
}
</script>

<style scoped>
.body {
    background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
    width: 100%;
    min-height: 2050px;
    background: url(../../../assets/mobile/img/support/banner.jpg) center no-repeat;
    background-size: 230%;
}

/* switchBar区域 */
.switchBar {
    padding-top: 50px;
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #ffffff;
}

.switchBar .switchBox {
    margin: 0 auto;
    text-align: center;
    width: 1500px;
    height: 200px;
    font-size: 82px;
    line-height: 200px;
    color: #ffffff;
    border-radius: 30px;
    background-color: #c3a35d;
}

.line {
    margin: 0 auto;
    width: 90%;
    border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
    padding: 50px;
    width: 100%;
    min-height: 500px;
}

.container .containerBox {
    padding: 100px;
    width: 100%;
    min-height: 300px;
    font-size: 70px;
    text-align: justify;
    background-color: #ffffff;
}
</style>